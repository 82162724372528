import React from 'react';
import './style.sass';

export const HTMLContent = ({ content, className }) => (
  <article
    className={className}
    dangerouslySetInnerHTML={{ __html: content }}
  />
);

const Content = ({ content, className }) => (
  <article className={className}>{content}</article>
);

export default Content;
