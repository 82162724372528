import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import Seo from '../components/Seo';

export default props => {
  const data = props.data.markdownRemark.frontmatter;
  const html = props.data.markdownRemark.html;
  const id = props.data.markdownRemark.id;
  const PostContent = HTMLContent || Content;

  console.log(data);
  console.log(html);
  console.log(id);
  return (
    <Layout>
      <Seo
        title={data.title}
        description={data.description}
        href={props.location.href}
      />
      <div>{data.title}</div>
      <PostContent content={html} />
      {/* <PreviewCompatibleImage
        imageInfo={{
          image: data.featuredimage,
          alt: `${data.title}`,
        }}
      /> */}
    </Layout>
  );
};

export const pageQuery = graphql`
  query fortuneById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        selectedIds
        tags
      }
    }
  }
`;

// export const pageQuery = graphql`
//   query fortuneById($id: String!) {
//     markdownRemark(id: { eq: $id }) {
//       id
//       html
//       frontmatter {
//         title
//         date(formatString: "MMMM DD, YYYY")
//         description
//         selectedIds
//         tags
//         featuredimage {
//           childImageSharp {
//             fluid(maxWidth: 120, quality: 100) {
//               ...GatsbyImageSharpFluid
//             }
//           }
//         }
//       }
//     }
//   }
// `;
